import domReady from '@wordpress/dom-ready';
import { Fancybox } from '@fancyapps/ui';

domReady(() => {
  const galleryImages = document.querySelectorAll('.wp-block-gallery img');
  Fancybox.bind('[data-fancybox]', {});

  if (0 < galleryImages.length) {
    Fancybox.bind('.wp-block-gallery img', {});
  }
});
